<template>
  <div class="col-md-6 mb-3 relative" :class="{focusDonor: donateOnBehalf}" v-if="user">
    <!--DONOR-->
    <CardPlain v-if="donateAs && donatingType === 'organisation'">
      <div class="row mx-0 justify-content-center text-center text-md-start">
        <div class="col-md-12 letter_spacing green-text-dark font13 bold mb-3">
          DONATING AS:
        </div>
        <div class="col-12 bold green-text-dark mb-2">
          <div class="row mx-0 justify-content-center align-items-center" >
            <div class="col-12 col-md-auto p-0 text-center">
              <div class="profile_img m-auto">
                <Image :imageName="donateAs.imageUrl" width="50" theClass="profile_img" v-if="donateAs.imageUrl" />
                <!-- <img :src="url + '/static/uploads/images/' + donateAs.imageUrl" v-if="donateAs.imageUrl" class="profile_img"> -->
                <IconUser size="sizefull" v-else />
              </div>
            </div>
            <div class="col-12 col-md text-center text-md-start">
              <div class="row mx-0" >
                <div class="col-12 p-0 bold green-text font19">
                  {{donateAs.registeredName}}
                </div>
                <div class="col-12 p-0  medium green-text font10 letter_spacing">
                  ORGANISATION ID: {{donateAs.organisationID}}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-12 bold green-text-dark" v-html="displayOrganisationAddress(donateAs)">
        </div>
        <div class="col-12 mt-3">
          <SelectDonor
            id="selectDonor"
            placeholder="Change Donor"
            @applyfilter="selectDonor"
            title="Change Donor"
            bg="bg_green"
            color="white-text"
            size="small"
          >
            <ios-shuffle-icon class="inline-icon small"/>
          </SelectDonor>
        </div>
      </div>
    </CardPlain>
    <CardPlain v-else-if="donateAs && donatingType === 'group'">
      <div class="row mx-0 justify-content-center text-center text-md-start">
        <div class="col-md-12 letter_spacing green-text-dark font13 bold mb-3">
          DONATING AS:
        </div>
        <div class="col-12 bold green-text-dark mb-2">
          <div class="row mx-0 justify-content-center align-items-center" >
            <div class="col-12 col-md-auto p-0 text-center">
              <div class="profile_img m-auto">
                <Image :imageName="donateAs.donor.imageUrl" width="50" theClass="profile_img" v-if="donateAs.donor.imageUrl" />
                <!-- <img :src="url + '/static/uploads/images/' + donateAs.donor.imageUrl" v-if="donateAs.donor.imageUrl" class="profile_img"> -->
                <IconUser size="sizefull" v-else />
              </div>
            </div>
            <div class="col-12 col-md text-center text-md-start">
              <div class="row mx-0" >
                <div class="col-12 p-0 bold green-text font19">
                  {{donateAs.donor.firstName}} {{donateAs.donor.lastName}}
                </div>
                <div class="col-12 p-0  medium green-text font10 letter_spacing">
                  DONOR ID: {{donateAs.donor.donorID}}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-12 bold green-text-dark" v-html="displayDonorAddress(donateAs.donor)">
        </div>
        <div class="col-12 mt-3">
          <SelectDonor
            id="selectDonor"
            placeholder="Change Donor"
            @applyfilter="selectDonor"
            title="Change Donor"
            bg="bg_green"
            color="white-text"
            size="small"
          >
            <ios-shuffle-icon class="inline-icon small"/>
          </SelectDonor>
        </div>
      </div>
    </CardPlain>
    <CardPlain v-else-if="donateAs && donatingType === 'user'">
      <div class="row mx-0 justify-content-center text-center text-md-start">
        <div class="col-md-12 letter_spacing green-text-dark font13 bold mb-3">
          DONATING AS:
        </div>
        <div class="col-12 bold green-text-dark mb-2">
          <div class="row mx-0 justify-content-center align-items-center" >
            <div class="col-12 col-md-auto p-0 text-center">
              <div class="profile_img m-auto">
                <Image :imageName="donateAs.imageUrl" width="50" theClass="profile_img" v-if="donateAs.imageUrl" />
                <IconUser size="sizefull" v-else />
              </div>
            </div>
            <div class="col-12 col-md text-center text-md-start">
              <div class="row mx-0" >
                <div class="col-12 p-0 bold green-text font19">
                  {{donateAs.firstName}}
                </div>
                <div class="col-12 p-0  medium green-text font10 letter_spacing">
                  DONOR ID: {{donateAs.donorID}}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-12 bold green-text-dark" v-html="displayDonorAddress(donateAs)">
        </div>
        <div class="col-12 mt-3">
          <SelectDonor
            id="selectDonor"
            placeholder="Change Donor"
            @applyfilter="selectDonor"
            title="Change Donor"
            bg="bg_green"
            color="white-text"
            size="small"
          >
            <ios-shuffle-icon class="inline-icon small"/>
          </SelectDonor>
        </div>
      </div>
    </CardPlain>
    <CardPlain v-else>
      <div class="row mx-0 justify-content-center text-center text-md-start">
        <div class="col-md-12 letter_spacing green-text-dark font13 bold mb-3">
          DONATING AS:
        </div>
        <div class="col-12 bold green-text-dark mb-2">
          <div class="row mx-0 justify-content-center align-items-center" >
            <div class="col-12 col-md-auto p-0 text-center">
              <div class="profile_img m-auto">
                <Image :imageName="user.imageUrl" width="50" v-if="user.imageUrl" />
                <!-- <img :src="url + '/static/uploads/images/' + user.imageUrl" v-if="user.imageUrl"> -->
                <!-- <img :src="user.profile_picture" class="profile_img" v-if="user.profile_picture" > -->
                <IconUser size="sizefull" v-else />
              </div>
            </div>
            <div class="col-12 col-md text-center text-md-start">
              <div class="row mx-0" >
                <div class="col-12 p-0 bold green-text font19">
                  {{user.firstName}} {{user.lastName}}
                </div>
                <div class="col-12 p-0  medium green-text font10 letter_spacing">
                  DONOR ID: {{user.userID}}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-12 bold green-text-dark" v-html="displayAddress(user.fullAddress)">
        </div>
        <div class="col-12 mt-3">
          <SelectDonor
            id="selectDonor"
            placeholder="Change Donor"
            @applyfilter="selectDonor"
            title="Change Donor"
            bg="bg_green"
            color="white-text"
            size="small"
          >
            <ios-shuffle-icon class="inline-icon small"/>
          </SelectDonor>
        </div>
      </div>
    </CardPlain>
    <!--END DONOR-->
  </div>
</template>

<script>

import { mapGetters, mapActions } from 'vuex'
import { defineAsyncComponent } from 'vue'

export default {
  components: {
    Image: defineAsyncComponent(() => import('@/components/Image.vue')),
    CardPlain: defineAsyncComponent(() => import('@/components/CardPlain.vue')),
    IconUser: defineAsyncComponent(() => import('@/components/icons/IconUser.vue')),
    SelectDonor: defineAsyncComponent(() => import('@/components/SelectDonor.vue')),
    'ios-shuffle-icon': defineAsyncComponent(() => import('vue-ionicons/dist/ios-shuffle.vue'))
  },
  name: 'Checkout Donating As',
  props: ['donateOnBehalf', 'donatingID'],
  emits: ['update:donatingID'],
  computed: {
    ...mapGetters([
      'user', 'userDonors', 'donateAs', 'countries', 'isApp'
    ]),
    donatingIDValue: {
      get () {
        return this.donatingID
      },
      set (val) {
        this.$emit('update:donatingID', val)
      }
    },
    donatingType () {
      if (this.donateAs) {
        if (this.donateAs.groupID) {
          return 'group'
        } else if (this.donateAs.organisationID) {
          return 'organisation'
        } else {
          return 'user'
        }
      } else {
        return 'user'
      }
    }
  },
  methods: {
    ...mapActions(['setDonateAs']),
    selectDonor (val) {
      const type = val[0].slice(0, 3)
      this.donatingIDValue = parseInt(val[0].slice(3))
      this.setDonateAs(val[1].userDetails)
      if (type === 'org') {
        this.donatingType = 'organisation'
      } else if (type === 'gro') {
        this.donatingType = 'group'
      } else {
        this.donatingType = 'user'
      }
    },
    displayAddress (val) {
      var str = val.replace(/,/g, '<br>')
      return str.replace(/<br><br>/g, '<br>')
    },
    displayDonorAddress (val) {
      return val.postalAddress1 + '<br>' + val.postalAddress2 + '<br>' + val.town + '<br>' + val.postalcode
    },
    displayOrganisationAddress (val) {
      const address = val.addressList[0]
      return address.addressLine1 + '<br>' + address.addressLine2 + '<br>' + address.town + '<br>' + address.postalCode
    },
    displayGroupAddress (val) {
      const address = val.addressList[0]
      return address.addressLine1 + '<br>' + address.addressLine2 + '<br>' + address.town + '<br>' + address.postalCode
    }
  }
}
</script>

<style scoped>

.profile_img {
  height: 50px;
  width: 50px;
  border-radius: 50%;
  position: relative;
  border: 2px solid #fff;
  background-color: rgba(255, 255, 255, 0.9);
  /* box-shadow: 5px 5px 35px 0px rgb(0 0 0 / 17%); */
}
.profile_img img {
  height: 100%;
  width: 100%;
  border-radius: 50%;
}
.focusDonor {
  animation: fadeAndZoom;
  animation-duration: 2s;
  animation-timing-function: ease;
}
@keyframes fadeAndZoom {
  0% {
    opacity: 0.5;
    transform: scale(1);
  }
  20% {
    opacity: 0.5;
  }
  50% {
    opacity: 1;
    transform: scale(1.2);
  }
  80% {
    opacity: 1;
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}
</style>
